<template>
  <div>
    <div>
      <div :class="isMobile ? 'search-mobile' : 'search-field'">
        <input
          v-model="query"
          v-on:keyup="handleSearch"
          type="text"
          placeholder="Pesquisar colaborador(a)"
        />
        <span class="search-icon">
          <i class="el-icon-search"></i>
        </span>
        <span v-if="query && query.length >= 1" class="close-icon" v-on:click="clear">
          <i class="el-icon-close"></i>
        </span>
        <div
          v-if="loaded && colaborators.length > 0"
          :class="'search-result ' + (isMobile ? 'search-result-mobile' : '')"
        >
          Resultado da Pesquisa:
        </div>
        <div
          v-if="loaded && colaborators.length == 0"
          :class="'search-result ' + (isMobile ? 'search-result-mobile' : '')"
        >
          Não encontramos colaboradores com esse nome.
        </div>
      </div>
      <div v-if="isLoading" class="col d-flex align-items-center flex-column mt-5">
        <div class="loader mt-5"></div>
        <div class="txt-galera">Buscando colaboradores...</div>
      </div>
      <UserSearchList :users="colaborators" :overflow="isMobile" />
    </div>
  </div>
</template>

<script>
import UserSearchList from "./UserSearchList.vue";
import _ from "lodash";

export default {
  components: {
    UserSearchList,
  },
  data() {
    return {
      colaborators: [],
      query: "",
      loaded: false,
      isLoading: false,
    };
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  methods: {
    clear: function () {
      this.query = "";
      this.loaded = false;
      this.colaborators = [];
      this.$emit("hide-home-users", false);
    },
    handleSearch: _.debounce(async function (event) {
      this.colaborators = [];
      if (!this.query || this.query.length == 0) {
        this.$emit("hide-home-users", false);
      } else {
        this.$emit("hide-home-users", true);
      }
      this.loaded = false;

      if (this.query && this.query.length >= 3 && this.query.length <= 200) {
        this.isLoading = true;
        try {
          let response = await this.$axios.post(
            "/people/search_colabs",
            { query: this.query },
            { withCredentials: true }
          );
          this.colaborators = response.data;
        } catch (error) {
          if (error.status != 401) {
            this.$toast.open({
              message: `Erro: ${error}`,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-left",
            });
          }
        } finally {
          this.loaded = true;
          this.isLoading = false;
        }
      }
    }, 700),
  },
};
</script>

<style scoped lang="scss">
input[type="text"] {
  height: 32px;
  line-height: 32px;
  padding: 12px 36px;
  border: 1px solid #d9d9d9;
  color: #606266;
  font-size: 14px;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  width: 100%;
}
input[type="text"]:hover {
  border-color: #c0c4cc;
}
input[type="text"]:focus {
  border-color: #e87305;
}
::placeholder {
  color: #c0c4cc;
}

.search-field {
  padding-right: 20px;
}

.search-mobile {
}

.search-result {
  margin-left: 10px;
  margin-top: 9px;
  margin-bottom: 9px;
  font-size: 12px;
  color: #a8a8a8;
}

.search-result-mobile {
  font-size: 14px;
}

.search-icon {
  position: absolute;
  margin-top: 4px;
  color: #d9d9d9;
  left: 23px;
  height: 32px;
  width: 32px;
}
.close-icon {
  position: absolute;
  margin-top: 4px;
  margin-left: -22px;
  color: #4b4b4b;
  height: 32px;
  width: 32px;
  cursor: pointer;
}

.loader {
  border: 2px solid #f3f3f3;
  border-radius: 100%;
  border-top: 2px solid #e87530;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 0.8s linear infinite; /* Safari */
  animation: spin 0.8s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
