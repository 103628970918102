<template>
  <div :class=" 'list-card '+ (isMobile ? 'list-card-mobile' : 'list-card-desktop') + (overflow ? ' overflow-list' : '')">
    <div class="col-12">
      <div class="column gy-2 gx-2">
        <div v-for="(user, index) in users" :key="index">
          <UserSearchListCard :user="user" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserSearchListCard from './UserSearchListCard.vue';
export default {
  props: {
    users: { type: Array }, title: String, birthdayIcon: Boolean, overflow: Boolean
  },computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  components: {
    UserSearchListCard,
  },
  methods: {
    calculateHeight: function () {
      let vh = window.innerHeight - 208;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
  },
  async created() {
    if (this.isMobile) {
      this.calculateHeight()
      window.addEventListener("resize", this.calculateHeight());
    }
  },
};
</script>
<style scoped lang="scss">
.list-card {
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 15px;
    margin-right: -10px;

    border: none;
    cursor: pointer;
    color: #808080;
}

.list-card-desktop {
  max-height: calc(100vh - 208px);
}

.overflow-list {
  max-height: var(--vh, 100vh);
}

.list-card-mobile {
  margin-right: 0px;
}

::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 10px;
}

</style>