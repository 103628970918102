<template>
  <router-link :to="link" class="link">
    <div class="d-flex user card">
      <div class="d-flex align-items-center data-card">
        <Avatar
          :src="photoUrl"
          :username="formattedNickname(user.nickname)"
          :size="60"
          background-color="#E87305"
          color="#fff"
        ></Avatar>
        <div class="data">
          <div class="fw-bold txt-primary">{{ titleCase(user.nickname) }}</div>
          <div class="txt-secondary">{{ user.email }}</div>
          <div class="txt-secondary">
            {{
              user.galera &&
              user.galera.communication &&
              user.galera.communication.cellphone
            }}
          </div>
        </div>
        <div class="d-flex align-self-end icons">
          <img
            v-if="user.cipa"
            class="pe-2"
            src="../../assets/icons/cipa.svg"
            alt="CIPA"
          />
          <img
            v-if="user.brigada"
            class="pe-2"
            src="../../assets/icons/brigada.svg"
            alt="Brigada"
          />
          <img
            v-if="birthdayIcon"
            class="pe-2"
            src="../../assets/icons/birthday.svg"
            alt="Aniversário"
          />
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import Avatar from "vue-avatar";
import { formattedNickname, titleCase } from "../../helpers/name";
export default {
  components: {
    Avatar,
  },
  props: { user: { type: Object }, birthdayIcon: Boolean },
  computed: {
    link() {
      return `/users/${this.user && this.user.login}`;
    },
    photoUrl() {
      return this.user.login 
        ? `${this.$store.state.baseUrl}/photo/${this.user.login}`
        : "";
    },
  },
  methods: {
    formattedNickname(nickname) {
      return formattedNickname(nickname);
    },
    titleCase (nickname) {
      return titleCase(nickname)
    }
  },
};
</script>
<style scoped lang="scss">
.user.card {
  background-color: #fafafa;
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  cursor: pointer;
  height: 70px;
  width: 100%;
  color: #808080;
  align-items: center;
}
.data {
  margin-left: 10px;
}

.data-card {
  flex: 1;
}

.icons {
  flex: 1;
  margin-bottom: 10px;
  justify-content: flex-end;
}

.txt-primary {
  font-size: 14px;
  color: #4b4b4b;
}

.link {
  text-decoration: none;
}

.txt-secondary {
  font-size: 12px;
}
</style>
