const _ = require('lodash')

const formattedNickname = function (nickname) {
  if (!nickname)
    return '';
  let connectors = [' DA ', ' DO ', ' DE ', ' D ', ' D. ']
  let name = nickname.toUpperCase()
  for (let i = 0; i < connectors.length; i++) {
    let connector = connectors[i];
    name = name.replace(connector, ' ');
  }
  let s = name.trim().split(" ");
  name = s[0] + (s.length > 1 ? ' ' + s[1] : '');
  return name;
}

const titleCase = function (nickname) {
  if (!nickname)
    return '';

  let re = /[ ][D][a-z][ ]/g;
  let indexList = []
  let match;
  while ((match = re.exec(nickname)) != null) {
    indexList.push(match.index + 1)
  }

  let name = _.toLower(nickname).split(" ").reduce((previousValue, currentValue) =>
    previousValue + " " + _.toUpper(currentValue.substring(0, 1)) + currentValue.substring(1, currentValue.length),
    "").trim().replace(/[ ]D.[ ]/g, (x) => _.toLower(x))
  for (let i = 0; i < indexList.length; i++) {
    let index = indexList[i];
    name = name.substring(0, index) + 'D' + name.substring(index + 1);
  }
  return name;
}

module.exports = {
  formattedNickname: formattedNickname,
  titleCase: titleCase
}