<template>
  <div class="row navbar mb-3">
    <div class="col">
      <nav class="d-flex flex-row galera-nav-bar">
        <div>
          <router-link to="/">
            <img
              alt="Logo do Galera"
              class="logoheader"
              src="../../assets/img/Galera_Header.svg"
            />
          </router-link>
        </div>
        <div class="d-flex flex-row links-sections">
          <div v-if="hasAuthorization && !isMobile" class="d-flex links-subsection">
            <div
              class="d-flex"
              :class="
                routeName == 'Home' || routeName == 'User'
                  ? 'link-bearer-selected'
                  : 'link-bearer'
              "
            >
              <router-link
                to="/"
                :class="
                  routeName == 'Home' || routeName == 'User' ? 'link-selected' : 'link'
                "
              >
                Colaboradores
              </router-link>
            </div>
            <div
              v-if="isAuthorized([Role.Reports])"
              class="d-flex link-bearer"
              :class="routeName == 'Reports' ? 'link-bearer-selected' : 'link-bearer'"
            >
              <a class="link" href="https://datastudio.google.com/" target="_blank">
                Relatórios
              </a>
            </div>
            <div
              v-if="isAuthorized([Role.Relationship])"
              class="d-flex link-bearer"
              :class="
                routeName == 'Relationships' ||
                routeName == 'Employee' ||
                routeName == 'Costcenter'
                  ? 'link-bearer-selected'
                  : 'link-bearer'
              "
            >
              <router-link
                to="/relationships"
                :class="
                  routeName == 'Relationships' ||
                  routeName == 'Employee' ||
                  routeName == 'Costcenter'
                    ? 'link-selected'
                    : 'link'
                "
              >
                Vínculos
              </router-link>
            </div>
            <div
              v-if="isAuthorized([Role.Outsourced])"
              class="d-flex link-bearer"
              :class="routeName == 'Outsourced' ? 'link-bearer-selected' : 'link-bearer'"
            >
              <router-link
                to="/outsourced"
                :class="routeName == 'Outsourced' ? 'link-selected' : 'link'"
              >
                Terceiros
              </router-link>
            </div>
            <div
              v-if="isAuthorized([Role.Admin])"
              class="d-flex link-bearer"
              :class="routeName == 'Admin' ? 'link-bearer-selected' : 'link-bearer'"
            >
              <router-link
                to="/admin"
                :class="routeName == 'Admin' ? 'link-selected' : 'link'"
              >
                Admin
              </router-link>
            </div>
            <div
              v-if="isAuthorized([Role.Email])"
              class="d-flex link-bearer"
              :class="
                routeName == 'EmailDefinition' ? 'link-bearer-selected' : 'link-bearer'
              "
            >
              <router-link
                to="/email_definition"
                :class="routeName == 'EmailDefinition' ? 'link-selected' : 'link'"
              >
                Definir E-mail
              </router-link>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <AppsMenu />
          <ProfileMenu :user="user" />
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import AppsMenu from "./AppsMenu.vue";
import ProfileMenu from "./ProfileMenu.vue";
import Role from "../../helpers/role";
import _ from "lodash";

export default {
  props: { loadingUser: Boolean, setUserLoading: Function },
  components: {
    AppsMenu,
    ProfileMenu,
  },
  beforeCreate() {
    this.Role = Role;
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
    user() {
      return this.$store.state.user;
    },
    hasAuthorization() {
      let permissions =
        (this.$store.state.user &&
          this.$store.state.user &&
          this.$store.state.user.galera &&
          this.$store.state.user.galera.permissions) ||
        [];
      // check if route is restricted by role
      return permissions.length > 0;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  methods: {
    isAuthorized(authorize) {
      if (authorize) {
        let permissions =
          (this.$store.state.user &&
            this.$store.state.user &&
            this.$store.state.user.galera &&
            this.$store.state.user.galera.permissions) ||
          [];
        // check if route is restricted by role

        if (authorize.length && _.difference(authorize, permissions).length != 0) {
          // role not authorised so redirect to home page
          return false;
        }
      }
      return true;
    },
  },
  async mounted() {
    try {
      this.$store.commit("setLoadingUser", true);
      const resp = await this.$axios.get("me", { withCredentials: true });
      this.$store.commit("setUser", resp.data);
    } catch (error) {
      if (error.status != 401) {
        this.$toast.open({
          message: error.data,
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-left",
        });
      }
    } finally {
      this.$store.commit("setLoadingUser", false);
      let permissions =
        (this.$store.state.user &&
          this.$store.state.user &&
          this.$store.state.user.galera &&
          this.$store.state.user.galera.permissions) ||
        [];
      // check if route is restricted by role
      if (
        this.$route.meta &&
        this.$route.meta.authorize &&
        this.$route.meta.authorize.length &&
        _.difference(this.$route.meta.authorize, permissions).length != 0
      ) {
        this.$router.push({ path: "/" });
      }
    }
  },
};
</script>
<style scoped lang="scss">
.navbar {
  background-color: #f5f7fa;
  padding: 0 26px 0 26px;
}

.logoheader {
  cursor: pointer;
  margin: 9px 0px;
  height: 44px;
  width: 149px;
}

.galera-nav-bar {
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.links-sections {
  justify-content: space-between;
  flex: 1;
  font-size: 14px;
  color: #4b4b4b;
  height: 100%;
}
.links-subsection {
  justify-content: flex-start;
  margin-left: 100px;
}
.link-bearer {
  padding: 0px 12px 0px 12px;
  align-items: center;
}

.link-bearer-selected {
  padding: 0px 12px 0px 12px;
  border-top: 2px solid transparent;
  align-items: center;
  border-bottom: 2px solid #ff7b00;
}

.link-selected {
  text-decoration: none;
  color: #ff7b00;
}

.link {
  text-decoration: none;
  color: #4b4b4b;
}
</style>
