<template>
  <div v-if="isMaintenance" >
      <Maintenance />
  </div>
  <div v-else :class="isMobile ? 'login-home-mobile' : 'login-home'">
    <div class="login container">
      <div class="row justify-content-center mt-4">
        <div class="col-sm-8 col-md-5 card">
          <div class="row justify-content-center">
            <img
              alt="Logo do Galera"
              class="img-fluid logo"
              src="../assets/img/logo.svg"
            />
          </div>
          <div class="row app-name justify-content-center">Galera</div>
          <hr />
          <div class="row justify-content-center">
            <a :href="loginUrl" class="login-button"> Email CESAR </a>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-sm-8 col-md-5 lgpd-text">
            A utilização da solução GALERA é exclusiva para colaboradores CESAR, via
            acesso individual com login e senha. É terminantemente proibido o uso indevido
            ou ilegal de qualquer informação a que se tenha acesso por meio do GALERA,
            especialmente quanto a dados pessoais de colaboradores, para o estrito
            cumprimento à Lei Geral de Proteção de Dados (LGPD).
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Maintenance from "./Maintenance";
export default {
  components: {
    Maintenance
  },
  computed: {
    loginUrl() {
      return `${process.env.VUE_APP_SERVER_URL}/auth/google/login`;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },    
    isMaintenance() {      
      return !!process.env.VUE_APP_MAINTANCE_MODE
    }
  },
  methods: {
    calculateHeight: function () {
      let vh = window.innerHeight - 60;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
  },
  async created() {
    if (this.isMobile) {
      this.calculateHeight()
      window.addEventListener("resize", this.calculateHeight());
    }
  },
};
</script>

<style scoped>
.logo {
  width: 200px;
  height: 250px;
}

.login {
  display: flex;
  align-items: center;
  padding: 0 55px;
  min-height: calc(100vh - 60px);
}

.login-home {
  max-height: calc(100vh - 60px);
  overflow-y: auto;
}

.login-home-mobile {
  max-height: var(--vh, 100vh);
  overflow-y: auto;
}

.login-button {
  border: 0;
  padding-left: 45px;
  padding-top: 8px;
  cursor: pointer;
  width: 146px;
  height: 40px;
  color: white;
  text-decoration: none;
  font-size: 14px;
  background: url("../assets/img/login-button.svg") left center no-repeat;
}

.app-name {
  color: #4b4b4b;
  font-size: 2.5em;
  margin-top: -1.5em;
  margin-bottom: 1.2em;
}

.lgpd-text {
  margin-top: 20px;
  text-align: justify;
  font-size: 0.8em;
  line-height: 1.3;
}

.card {
  padding: 60px 20px 20px 20px;
  text-align: center;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  border: 1px solid #ebeef5;
}

hr {
  display: block;
  height: 1px;
  width: 100%;
  margin: 24px 0;
  color: lightgray;
}
</style>
