<template>
  <div class="container-fluid">
    <NavGalera />
    <div :class="isMobile ? 'col' : 'row'">
      <div v-if="isHome || isSkills || ((isUser || isEdit) && !isMobile)" class="col-xs-12 col-md-3">
        <UserSearcher @hide-home-users="hideHome" />
      </div>
      <div class="col home">
        <HomeList :hideHomeUsers="hideHomeUsers" v-if="isHome" />
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import NavGalera from "../components/shared/NavGalera.vue";
import UserSearcher from "../components/shared/UserSearcherColabs.vue";
import HomeList from "../components/shared/HomeList.vue";

export default {
  components: {
    NavGalera,
    UserSearcher,
    HomeList,
  },
  data() {
    return {
      birthdays: null,
      recent: null,
      isLoading: false,
      errorMessage: "",
      loadingUser: false,
      hideHomeUsers: false,
    };
  },
  methods: {
    hideHome: function (hideHomeUsers) {
      this.hideHomeUsers = hideHomeUsers;
    },
  },
  computed: {
    isHome() {
      return this.$route.name == "Home";
    },
    isUser() {
      return this.$route.name == "User";
    },
    isEdit() {
      return this.$route.name == "Edit";
    },
    isSkills() {
      return this.$route.name == 'Skills'
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
};
</script>

<style scoped lang="scss">
body {
  font-family: "Helvetica Neue", Arial, sans-serif;
  color: #4b4b4b;
}

footer {
  font-size: 12px;
  color: #a8a8a8;
  text-align: center;
}

.home {
  overflow-y: auto;
}

.loader {
  border: 2px solid #f3f3f3;
  border-radius: 100%;
  border-top: 2px solid #e87530;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 0.8s linear infinite; /* Safari */
  animation: spin 0.8s linear infinite;
}

.txt-galera {
  color: #e87530;
  text-align: center;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
