<template>
  <div class="internal-box">
    <table>
      <tr>
        <div class="professional-title">Dados Profissionais</div>
      </tr>
      <tr>
        <td>
          <div class="d-flex label">Matrícula:</div>
        </td>
        <td>
          <div
            v-if="user.latest && user.latest.relationship && user.latest.relationship.badge"
            class="d-flex information-text fw-bold"
          >
            {{ user.latest.relationship.badge }}
          </div>
          <div v-else class="d-flex information-text unknown">Não informada</div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="d-flex label">Conhecido Por:</div>
        </td>
        <td>
          <div v-if="user.nickname" class="d-flex information-text fw-bold">
            {{ user.nickname }}
          </div>
          <div v-else class="d-flex information-text unknown">Não informado</div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="d-flex label">E-mail:</div>
        </td>
        <td>
          <div v-if="user.email" class="d-flex information-text fw-bold">
            {{ user.email }}
          </div>
          <div v-else class="d-flex information-text unknown">Não informado</div>
        </td>
      </tr>
      <tr v-if="own">
        <td>
          <div class="d-flex label">Times:</div>
        </td>
        <td>
          <div v-if="user.qulture && user.qulture.teams" class="d-flex information-text fw-bold">
            {{ user.qulture.teams.toString().replace(",", ", ") }}
          </div>
          <div v-else class="d-flex information-text unknown">Não informada</div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="d-flex label">CC de Origem:</div>
        </td>
        <td>
          <div v-if="ccOrigem" class="d-flex information-text fw-bold">{{ ccOrigem }}</div>
          <div v-else class="d-flex information-text unknown">Não informado</div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="d-flex label">Slack ID:</div>
        </td>
        <td class="d-flex">
          <div v-if="user.slack" class="d-flex link information-text blue fw-bold">
            {{ user.slack }}
          </div>
          <div v-else class="d-flex information-text unknown">Não informado</div>
          <img
            v-if="user.slack"
            @click="slackRedirect"
            class="icon"
            src="../../assets/icons/slack_mark.svg"
            alt="SLACK"
            title="Conversar pelo slack"
          />
        </td>
      </tr>
      <tr>
        <td>
          <div class="d-flex label">Currículo Lattes:</div>
        </td>
        <td>
          <a
            v-if="user.galera && user.galera.lattes"
            @click="lattesRedirect"
            class="d-flex url-linked link information-text blue fw-bold"
          >
            {{ user.galera && user.galera.lattes }}
          </a>
          <div v-else class="d-flex information-text unknown">Não informado</div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="d-flex label">LinkedIn:</div>
        </td>
        <td>
          <a
            v-if="user.galera && user.galera.linkedin"
            @click="linkedinRedirect"
            class="d-flex url-linked link information-text blue fw-bold"
          >
            {{ user.galera && user.galera.linkedin }}
          </a>
          <div v-else class="d-flex information-text unknown">Não informado</div>
        </td>
      </tr>
      <tr>
        <div class="professional-title">Dados Acadêmicos</div>
      </tr>
      <tr>
        <td>
          <div class="d-flex label">Escolaridade:</div>
        </td>
        <td>
          <div v-if="user.education_level" class="d-flex information-text fw-bold">
            {{ user.education_level.name }}
          </div>
          <div v-else class="d-flex information-text unknown">Não informada</div>
        </td>
      </tr>
      <tr>
        <div class="personal-title">Dados Pessoais</div>
      </tr>
      <tr>
        <td>
          <div class="d-flex label">Celular:</div>
        </td>
        <td class="d-flex">
          <div
            v-if="user.galera && user.galera.communication && user.galera.communication.cellphone"
            class="d-flex information-text blue fw-bold"
          >
            {{ user.galera && user.galera.communication && user.galera.communication.cellphone }}
          </div>
          <div v-else class="d-flex information-text unknown">Não informado</div>
          <img
            v-if="user.galera && user.galera.communication && user.galera.communication.cellphone"
            @click="whatsappRedirect"
            class="icon"
            src="../../assets/icons/whats_app.svg"
            alt="WHATSAPP"
            title="Conversar pelo WhatsApp"
          />
        </td>
      </tr>
      <tr>
        <td>
          <div class="d-flex label">Contato Familiar:</div>
        </td>
        <td>
          <div
            v-if="
              user.galera &&
              user.galera.communication &&
              user.galera.communication.family_contact &&
              user.galera.communication.family_contact.name
            "
            class="d-flex information-text fw-bold"
          >
            {{
              user.galera &&
              user.galera.communication &&
              user.galera.communication.family_contact &&
              user.galera.communication.family_contact.name
            }}
          </div>
          <div v-else class="d-flex information-text unknown">Não informado</div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="d-flex label">Telefone do Familiar:</div>
        </td>
        <td>
          <div
            v-if="
              user.galera &&
              user.galera.communication &&
              user.galera.communication.family_contact &&
              user.galera.communication.family_contact.cellphone
            "
            class="d-flex information-text fw-bold"
          >
            {{
              user.galera &&
              user.galera.communication &&
              user.galera.communication.family_contact &&
              user.galera.communication.family_contact.cellphone
            }}
          </div>
          <div v-else class="d-flex information-text unknown">Não informado</div>
        </td>
      </tr>
      <tr
        v-if="
          user.galera && user.galera.consents && user.galera.consents.birthday_news !== undefined
        "
      >
        <td>
          <div class="d-flex label">Autorização 1:</div>
        </td>
        <td>
          <div class="fw-bold text-autorization">
            [{{ user.galera.consents.birthday_news ? "Sim" : "Não" }}] Autorizo o uso de informações
            sobre o dia e o mês de meu aniversário, para a finalidade de divulgação em comunições
            internas do CESAR, a exemplo de "Aniversariante do Dia" e na lista dos aniversariantes
            da semana da Destaque News.
          </div>
        </td>
      </tr>
      <tr
        v-if="
          user.galera && user.galera.consents && user.galera.consents.communication !== undefined
        "
      >
        <td>
          <div class="d-flex label">Autorização 2:</div>
        </td>
        <td>
          <div class="fw-bold text-autorization">
            [{{ user.galera.consents.communication ? "Sim" : "Não" }}] Autorizo a coleta dos dados
            pessoais, como: número de Celular, nome e telefone de Contato familiar para emergências
            com a finalidade de facilitar a comunicação entre mim e o CESAR.
          </div>
        </td>
      </tr>
      <tr v-if="own && !isPrestadorOuOutros">
        <div class="personal-title">Dados Pessoais Sensíveis</div>
      </tr>
      <tr v-if="own && !isPrestadorOuOutros">
        <td>
          <div class="d-flex label">Tamanho da Camisa:</div>
        </td>
        <td>
          <div
            v-if="
              user.galera &&
              user.galera.sensitive &&
              user.galera.sensitive.sizes &&
              user.galera.sensitive.sizes.tshirt
            "
            class="d-flex information-text fw-bold"
          >
            {{
              user.galera &&
              user.galera.sensitive &&
              user.galera.sensitive.sizes &&
              user.galera.sensitive.sizes.tshirt
            }}
          </div>
          <div v-else class="d-flex information-text unknown">Não informado</div>
        </td>
      </tr>
      <tr v-if="own && !isPrestadorOuOutros">
        <td>
          <div class="d-flex label">Nº do sapato:</div>
        </td>
        <td>
          <div
            v-if="
              user.galera &&
              user.galera.sensitive &&
              user.galera.sensitive.sizes &&
              user.galera.sensitive.sizes.shoes
            "
            class="d-flex information-text fw-bold"
          >
            {{
              user.galera &&
              user.galera.sensitive &&
              user.galera.sensitive.sizes &&
              user.galera.sensitive.sizes.shoes
            }}
          </div>
          <div v-else class="d-flex information-text unknown">Não informado</div>
        </td>
      </tr>
      <tr v-if="own && !isPrestadorOuOutros">
        <td>
          <div class="d-flex label">Possui filho(s)?:</div>
        </td>
        <td>
          <div
            v-if="
              user.galera &&
              user.galera.sensitive &&
              user.galera.sensitive.special &&
              user.galera.sensitive.special.has_children
            "
            class="d-flex information-text fw-bold"
          >
            {{
              user.galera &&
              user.galera.sensitive &&
              user.galera.sensitive.special &&
              user.galera.sensitive.special.has_children
            }}
          </div>
          <div v-else class="d-flex information-text unknown">Não informado</div>
        </td>
      </tr>
      <tr v-if="own && !isPrestadorOuOutros">
        <td>
          <div class="d-flex label">Cor/Raça:</div>
        </td>
        <td>
          <div
            v-if="
              user.galera &&
              user.galera.sensitive &&
              user.galera.sensitive.special &&
              user.galera.sensitive.special.race
            "
            class="d-flex information-text fw-bold"
          >
            {{
              user.galera &&
              user.galera.sensitive &&
              user.galera.sensitive.special &&
              user.galera.sensitive.special.race
            }}
          </div>
          <div v-else class="d-flex information-text unknown">Não informada</div>
        </td>
      </tr>
      <tr v-if="own && !isPrestadorOuOutros">
        <td>
          <div class="d-flex label">Sexo/Gênero:</div>
        </td>
        <td>
          <div
            v-if="
              user.galera &&
              user.galera.sensitive &&
              user.galera.sensitive.special &&
              user.galera.sensitive.special.gender
            "
            class="d-flex information-text fw-bold"
          >
            {{
              user.galera &&
              user.galera.sensitive &&
              user.galera.sensitive.special &&
              user.galera.sensitive.special.gender
            }}
          </div>
          <div v-else class="d-flex information-text unknown">Não informado</div>
        </td>
      </tr>
      <tr
        v-if="
          user.galera &&
          user.galera.consents &&
          user.galera.consents.sensitive !== undefined &&
          user.galera.consents.sensitive.sizes !== undefined
        "
      >
        <td>
          <div class="d-flex label">Autorização 1:</div>
        </td>
        <td>
          <div class="fw-bold text-autorization">
            [{{ user.galera.consents.sensitive.sizes ? "Sim" : "Não" }}] Autorizo a coleta de dados
            sobre o tamanho de camisa/sapato e se possuo filho(s), para finalidade de uso em eventos
            da organização.
          </div>
        </td>
      </tr>
      <tr
        v-if="
          user.galera &&
          user.galera.consents &&
          user.galera.consents.sensitive !== undefined &&
          user.galera.consents.sensitive.personal !== undefined
        "
      >
        <td>
          <div class="d-flex label">Autorização 2:</div>
        </td>
        <td>
          <div class="fw-bold text-autorization">
            [{{ user.galera.consents.sensitive.personal ? "Sim" : "Não" }}] Autorizo a coleta e
            análise de dados pessoais sensíveis, como gênero, origem racial e deficiência, para uso,
            de maneira anonimizade e agrupada por setor, em relatórios e indicadores de diversidade
            organizacional.
          </div>
        </td>
      </tr>
    </table>
    <div v-if="own && isCLTOrEstagiario" class="address">
      <div class="address-info">
        <div class="address-title">Mudou de Endereço?</div>
        <div class="address-subtitle">
          Confira seu endereço cadastrado e, se preciso, acesse
          <a class="link" href="https://portalcolaborador.cesar.org.br/" target="_blank"
            >portalcolaborador.cesar.org.br</a
          >
          para atualizá-lo!
        </div>
        <div class="address-label">País:</div>
        <div class="address-value">
          {{ (user.address && user.address.country) || "--" }}
        </div>
        <div class="address-label">CEP:</div>
        <div class="address-value">
          {{ (user.address && user.address.cep) || "--" }}
        </div>
        <div class="address-double-cloumn">
          <div>
            <div class="address-label">Rua:</div>
            <div class="address-value">
              {{ (user.address && user.address.street) || "--" }}
            </div>
          </div>
          <div class="right-side">
            <div class="address-label">Número:</div>
            <div class="address-value">
              {{ (user.address && user.address.number) || "--" }}
            </div>
          </div>
        </div>
        <div class="address-label">Complemento:</div>
        <div class="address-value">
          {{ (user.address && user.address.complement) || "--" }}
        </div>
        <div class="address-double-cloumn">
          <div>
            <div class="address-label">Bairro:</div>
            <div class="address-value">
              {{ (user.address && user.address.neighborhood) || "--" }}
            </div>
          </div>
          <div class="right-side">
            <div class="address-label">Cidade:</div>
            <div class="address-value">
              {{ (user.address && user.address.city) || "--" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: Object,
    ccOrigem: String,
    slackRedirect: Function,
    whatsappRedirect: Function,
    linkedinRedirect: Function,
    lattesRedirect: Function,
    isPrestadorOuOutros: Boolean,
    isCLTOrEstagiario: Boolean,
  },
  computed: {
    own() {
      return (
        this.user &&
        this.$store.state &&
        this.$store.state.user &&
        this.user.login == this.$store.state.user.login
      );
    },
  },
};
</script>

<style scoped lang="scss">
.internal-box {
  display: flex;
}

.icon {
  margin-left: 8px;
  cursor: pointer;
}

.label {
  margin-right: 20px;
  color: #4b4b4b;
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 4px;
  text-align: right;
  white-space: nowrap;
  margin-left: 12px;
  justify-content: flex-end;
}

.professional-title {
  font-size: 16px;
  color: #a8a8a8;
  margin-top: 26px;
  margin-bottom: 20px;
}

.information-text {
  color: #4b4b4b;
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 4px;
  display: table-cell;
}

.information-text.blue {
  color: #0493c7;
}

.link {
  text-decoration: none;
  color: #0493c7;
}

.url-linked {
  cursor: pointer;
}

.personal-title {
  font-size: 16px;
  color: #a8a8a8;
  margin-top: 26px;
  margin-bottom: 20px;
}

.unknown {
  color: #a8a8a8;
}

.text-autorization {
  max-width: 400px;
  white-space: pre-line;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  color: #4b4b4b;
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 4px;
  display: table-cell;
}

.address {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  margin-right: 40px;
  height: min-content;
}

.address-title {
  color: #757575;
  font-weight: bold;
  font-size: 18px;
}

.address-info {
  margin-top: 26px;
  word-break: break-word;
  display: flex;
  flex-direction: column;
  padding: 24px 12px 24px 12px;
  border-radius: 4px;
  font-size: 14px;
  background-color: #f5f5f5;
  color: #757575;
  width: 75%;
}

.address-subtitle {
  margin-top: 24px;
  margin-bottom: 17px;
}

.right-side {
  width: 50%;
}

.address-double-cloumn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.address-label {
  margin-top: 15px;
  font-size: 12px;
  color: #4b4b4b;
}

.address-value {
  margin-top: 8px;
  color: #4b4b4b;
  font-weight: bold;
  font-size: 14px;
}
</style>
