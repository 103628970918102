<template>
  <div class="d-flex justify-content-around align-items-center not-found">
    <div class="align-text">
      <div class="d-flex justify-content-center pb-3">
        <img src="../assets/icons/error.svg" width="80" height="80">
      </div>
      <div class="nf-text">Essa página não foi encontrada</div>
    </div>
  </div>
</template>

<script>

export default {
};
</script>

<style scoped lang="scss">
.not-found {
  height: 100vh;
  background-color: #f7f8fb;
}

.nf-text {
  font-size: 1.5em;
  color: #47494e;
  text-align: center;
}

.align-text {
  display: flex;
  flex-direction: column;
}

</style>
