<template>
  <div class="row mb-5 justify-content-center" v-if="users && users.length > 0">
    <div class="col-12 mb-4">
      {{title}}
    </div>
    <div class="col-12">
      <div class="row gy-2 gx-2">
        <div v-for="(user, index) in users" :key="index" class="col-sm-6 col-md-4">
          <UserCard :user="user" :birthdayIcon="birthdayIcon"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UserCard from './UserCard.vue';

export default {
  props: {
    users: { type: Array }, title: String, birthdayIcon: Boolean,
  },
  components: {
    UserCard,
  },
};
</script>
<style scoped lang="scss">
</style>
