import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import VueMask from 'v-mask'
import Element from 'element-ui'
import VueClipboard from 'vue-clipboard2'
import VueCropper from 'vue-cropperjs';
import locale from 'element-ui/lib/locale/lang/pt-br'
import axios from 'axios';
import { Service } from 'axios-middleware';
import VueTelInput from 'vue-tel-input';
import App from './App.vue';
import router from './router';
import store from './store/index';
import ApiMiddleware from './middleware/ApiMiddleware'

import 'element-ui/lib/theme-chalk/index.css';
import 'bootstrap/dist/css/bootstrap-reboot.min.css';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'bootstrap/dist/css/bootstrap-utilities.min.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import 'cropperjs/dist/cropper.css';
import 'vue-tel-input/dist/vue-tel-input.css';
import './assets/scss/main.scss';

Vue.component('vue-cropper', VueCropper);
Vue.use(VueTelInput);

Vue.use(VueClipboard)

Vue.use(Element, { locale })

Vue.use(VueToast);

Vue.use(VueMask);

Vue.config.productionTip = false;

const service = new Service(axios);
let middleware = new ApiMiddleware();
service.register(middleware);

Vue.prototype.$axios = axios.create({ baseURL: `${process.env.VUE_APP_SERVER_URL}` });

var vm = new Vue({
  router,
  store,  
  render: (h) => h(App),
  created: function () {
    middleware.setVm(this);
    this.$store.commit("setIsMobile");

    router.beforeEach((to, from, next) => {
      // redirect to login page if not logged in and trying to access a restricted page
      const { authorize } = to.meta;
      if (authorize) {
          let permissions = (this.$store.state.user && this.$store.state.user && this.$store.state.user.galera && this.$store.state.user.galera.permissions) || [];
          // check if route is restricted by role
          
          if (authorize.length && _.difference(authorize, permissions).length != 0) {
              // role not authorised so redirect to home page
              return next({ path: '/' });
          }
      }
    
      next();
    })
  }
}).$mount('#app');
