<template>
  <section class="header-usermenu">
    <el-menu
      class="usermenu"
      text-color="#4b4b4b"
      mode="horizontal"
      index="1"
      ref="usermenu"
      menu-trigger="click"
    >
      <el-submenu
        class="usermenu__expanded"
        popper-class="usermenu--expanding"
        :show-timeout="200"
        :hide-timeout="200"
        index="1-1"
      >
        <template slot="title">
          <Avatar
            :username="formattedNickname(user.nickname)"
            :src="photoUrl"
            :size="32"
            background-color="#E87305"
            color="#fff"
            :key="count"
          ></Avatar>
        </template>
        <router-link :to="getUrl" class="link">
          <el-menu-item class="usermenu-item" index="1-1-1"
            >Minhas Informações</el-menu-item
          >
        </router-link>
        <el-menu-item @click="logout" class="usermenu-item" index="1-1-1"
          >Sair</el-menu-item
        >
      </el-submenu>
    </el-menu>
  </section>
</template>

<script>
import Avatar from "vue-avatar";
import { formattedNickname } from "../../helpers/name";
export default {
  components: {
    Avatar,
  },
  data() {
    return {
      isAppsMenuOpen: false,
    };
  },
  methods: {
    formattedNickname(nickname) {
      return formattedNickname(nickname);
    },
    async logout() {
      try {
        const resp = await this.$axios.post(
          "/auth/logout",
          {},
          { withCredentials: true }
        );
        this.$router.push({ path: "/login" });
      } catch (error) {
        if (error.status != 401) {
          this.$toast.open({
            message: `Erro: ${error}`,
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "bottom-left",
          });
        }
      }
    },
  },
  props: { user: { type: Object } },
  computed: {
    getUrl() {
      return `/users/${this.$store.state.user && this.$store.state.user.login}`;
    },
    photoUrl() {
      return this.$store.state.user.login
        ? `${this.$store.state.baseUrl}/photo/${this.$store.state.user.login}`
        : "";
    },
    count() {
      return this.$store.state.count;
    },
  },
};
</script>

<style lang="scss">
.link {
  text-decoration: none;
}

.header-usermenu {
  margin-left: 42px;
  & .el-submenu__icon-arrow {
    display: none;
  }
  & .el-submenu__title:hover {
    background-color: green;
  }
}

.usermenu__expanded .el-submenu__title {
  padding: 0;
  display: flex;
  align-items: center;
  & .el-menu--horizontal > .el-submenu {
    background-color: #ff7b00;
  }
}

.usermenu--expanding {
  margin-top: -5px;

  & .el-menu--popup-bottom-start {
    border-radius: 5%;
  }

  & .el-menu .usermenu-item:hover {
    color: #ff7b00 !important;
  }

  & .el-menu .usermenu-item:hover {
    color: #ff7b00 !important;
  }
}
</style>

<style lang="scss" scoped>
.usermenu /deep/ .el-submenu .el-submenu__title:hover {
  background-color: transparent;
}

.usermenu /deep/ .el-submenu.is-active .el-submenu__title {
  border-bottom: 2px solid #ff7b00;
}

.usermenu {
  background-color: #f5f7fa;
  border-bottom: none;

  &.is-active {
    background-color: #ff7b00;
  }

  &__expanded {
    & .el-submenu__title {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;

      &--none {
        padding: 0px;
        background-color: transparent;
      }
    }
  }
}

.header-usermenu /deep/ .el-submenu__title:hover {
  background-color: green;
}
</style>
