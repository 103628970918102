  <template>
    <div id="app">
      <router-view />
      <footer>
          <div class="left-text"> Versão {{version}} @ {{year}} CESAR All rights reserved.</div>
          <div class="right-text"> INFORMAÇÃO DE USO INTERNO </div>
      </footer>
    </div>
  </template>

  <script>
  const { DateTime } = require("luxon");
  export default {
    data() {
      return { version: process.env.VUE_APP_VERSION || 0 };
    },
    computed: {
      year() {
        return DateTime.now().toFormat("yyyy")
      }
    }
  };
  </script>

  <style scoped lang="scss">
  body {
      font-family: "Helvetica Neue", Arial, sans-serif;
      color: #4b4b4b;
  }

  footer {
      font-size: 12px;
      padding: 21px;
      color: #a8a8a8;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: transparent;

      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
  }

  .left-text {
    text-align: left;
  }

  .right-text {
    text-align: right;
  }
  </style>
