<template>
  <div class="home-list">
    <div class="col" v-if="!isLoading">
      <div v-if="isMobile" class="home-mobile">
        <div v-if="!hideHomeUsers">
          <div v-if="birthdays && birthdays.length > 0" class="search-result">Aniversariante(s) do Dia:</div>
          <UserSearchList :users="birthdays" />
          <div v-if="recent && recent.length > 0" class="search-result">Novos Colaboradores</div>
          <UserSearchList :users="recent" />
        </div>
      </div>
      <div v-else class="home-desktop">
        <UserList
          :users="birthdays"
          title="Aniversariante(s) do Dia"
          v-bind:birthdayIcon="true"
        />
        <UserList :users="recent" title="Novos Colaboradores" />
      </div>
    </div>
    <div v-else class="col d-flex align-items-center flex-column mt-5">
      <div class="loader mt-5"></div>
      <div class="txt-galera">Buscando aniversariantes e novos colaboradores...</div>
    </div>
  </div>
</template>

<script>
import UserList from "../home/UserList.vue";
import UserSearchList from "./UserSearchList.vue";

export default {
  components: {
    UserList,
    UserSearchList,
  },
  props: {
    hideHomeUsers: { type: Boolean },
  },
  data() {
    return {
      birthdays: null,
      recent: null,
      isLoading: false,
      errorMessage: "",
    };
  },
  methods: {
    calculateHeight: function () {
      let vh = window.innerHeight - 138;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  async created() {
    if (this.isMobile) {
      this.calculateHeight()
      window.addEventListener("resize", this.calculateHeight());
    }
    this.isLoading = true;
    try {
      const resp = await this.$axios.get("people/home", { withCredentials: true });
      this.birthdays = resp.data.birthdays;
      this.recent = resp.data.recent;
    } catch (error) {
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style scoped lang="scss">
.loader {
  border: 2px solid #f3f3f3;
  border-radius: 100%;
  border-top: 2px solid #e87530;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 0.8s linear infinite; /* Safari */
  animation: spin 0.8s linear infinite;
}

.search-result {
  margin-left: 10px;
  margin-top: 9px;
  margin-bottom: 9px;
  font-size: 14px;
  color: #a8a8a8;
}


.home-desktop {
  max-height: calc(100vh - 138px);
}

.home-mobile {
  max-height: var(--vh, 100vh);
}

.txt-galera {
  color: #e87530;
  text-align: center;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
