import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {},
    loadingUser: false,
    baseUrl: process.env.VUE_APP_SERVER_URL,
    isMobile: false
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setLoadingUser(state, loadingUser) {
      state.loadingUser = loadingUser
    },
    setIsMobile(state) {
      state.isMobile = isMobileResolution();
    }
  }
});


const isMobileResolution = () => {
  return window.screen.width < 640 || screen.height < 480
}
