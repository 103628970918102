<template>
  <div :class="isMobile ? 'user-mobile' : 'user'">
    <div v-if="!isLoading && user && !loadingUser">
      <div
        :class="
          'container-fluid d-flex justify-content-between ' +
            (isMobile ? 'header-mobile' : '')
        "
      >
        <div class="photo-section d-flex align-items-center">
          <Avatar
            :username="formattedNickname(user.nickname)"
            :size="100"
            :src="photoUrl"
            background-color="#e87305"
            color="#ffffff"
          />
          <div class="ps-3">
            <div class="txt-primary fw-bold">
              {{ user.nickname }}
            </div>
            <div class="txt-secondary">
              {{ user.email }}
            </div>
            <div class="d-flex">
              <div class="txt-secondary">
                {{
                  user.galera &&
                    user.galera.communication &&
                    user.galera.communication.cellphone
                    && formattedPhone(user.galera.communication.cellphone)
                }}
              </div>
              <img
                v-if="user.cipa"
                class="cipa-icon"
                src="../assets/icons/cipa.svg"
                alt="CIPA"
              >
              <img
                v-if="user.brigada"
                class="brigada-icon"
                src="../assets/icons/brigada.svg"
                alt="Brigada"
              >
            </div>
          </div>
        </div>
        <div :class="'d-flex align-items-start ' + (isMobile ? 'buttons-mobile' : '')">
          <router-link
            v-if="isEditing === false"
            to="/"
            :class="'link ' + (isMobile ? 'button-mobile' : '')"
          >
            <div class="back-button fw-bold">
              Voltar
            </div>
          </router-link>
          <el-button
            v-if="isEditing === true"
            size="small"
            class="fw-bold cancel-button"
            @click="edit(false)"
          >
            Cancelar
          </el-button>
          <el-button
            v-if="isEditing === false"
            size="small"
            class="edit-button fw-bold"
            @click="edit(true)"
          >
            Editar
          </el-button>
          <el-button
            v-if="isEditing === true"
            size="small"
            class="edit-button fw-bold"
            @click="save"
          >
            Salvar
          </el-button>
        </div>
      </div>
      <div class="horizontal-line" />
      <div class="information">
        <EditUserSkills
          :form-user="user"
          :is-editable="isEditing"
        />
      </div>
    </div>

    <div
      v-else-if="isLoading || loadingUser"
      class="col d-flex align-items-center flex-column mt-5"
    >
      <div class="loader mt-5" />
      <div class="txt-galera">
        Carregando informações...
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "vue-avatar";
import { formattedNickname } from "@/helpers/name";
import EditUserSkills from "@/components/edit/EditUserSkills.vue";
import _ from 'lodash';

export default {
  components: {
    EditUserSkills,
    Avatar,
  },
  data() {
    return {
      isLoading: false,
      isEditing: false,
      auxUser: null,
      activeTab: 'default'
    };
  },
  computed: {
    user() {
      return this.$route.params &&
        this.$route.params.login &&
        this.$store.state.user.login === this.$route.params.login
        ? this.$store.state.user
        : this.auxUser;
    },
    photoUrl() {
      return this.user.login
        ? `${this.$store.state.baseUrl}/photo/${this.user.login}`
        : "";
    },
    ownUser() {
      return this.$store.state.user;
    },
    loadingUser() {
      return this.$store.state.loadingUser;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  watch: {
    async $route(to, from) {
      await this.getUserData();
    },
    async ownUser(to, from) {
      await this.getUserData();
    },
  },
  async created() {
    if (this.isMobile) {
      this.calculateHeight()
      window.addEventListener("resize", this.calculateHeight());
    }
    await this.getUserData();
  },
  methods: {
    formattedPhone (phone) {
       if (phone.includes("_")) {
           let parts = phone.split("_");
           if(parts.length > 1) {
             return parts[1]
           }
           return parts[0]
       }
       return phone;
    },
    calculateHeight () {
      let vh = window.innerHeight - 138;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    formattedNickname(nickname) {
      return formattedNickname(nickname);
    },
    edit(editing) {
      this.isEditing = editing
    },
    isAbleToGetUserData() {
      return (
        this.$route.params &&
        this.$route.params.login &&
        this.$store.state.user &&
        this.$store.state.user.login &&
        (!this.auxUser || this.auxUser.login !== this.$route.params.login) &&
        this.$route.params.login !== this.$store.state.user.login
      );
    },
    async getUserData() {
      if (this.isAbleToGetUserData()) {
        this.isLoading = true;
        try {
          const resp = await this.$axios.get("people/user/" + this.$route.params.login, {
            withCredentials: true,
          });
          this.auxUser = resp.data;
        } catch (error) {
          this.$router.push({ path: "/" });
        } finally {
          this.isLoading = false;
        }
      }
    },
    async save() {
      try {
        this.isLoading = true;
        let updateObj = _.pick(this.user, ["galera.skills"]);

        console.log(updateObj)
        let response = await this.$axios.patch("/me", updateObj, {
          withCredentials: true,
        });

        if (response.status === 200) {
          const resp = await this.$axios.get("me", { withCredentials: true });
          this.$store.commit("setUser", resp.data);
          this.$router.push({ path: "/"});
          this.$toast.open({
            message: "Dados atualizados com sucesso",
            type: "success",
            duration: 5000,
            dismissible: true,
            position: "bottom-left",
          });
        }

        this.isEditing = false;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.buttons-mobile {
  margin-top: 27px;
}

.button-mobile {
  flex: 1;
}

.header-mobile {
  flex-direction: column;
}

.txt-primary {
  font-size: 18px;
  color: #000000;
}

.user {
  max-height: calc(100vh - 138px);
}

.user-mobile {
  max-height: var(--vh, 100vh);
}

.information {
  margin-left: 15px;
}

.horizontal-line {
  height: 1px;
  margin-top: 30px;
}

.internal-box {
}

.link {
  text-decoration: none;
}

.edit-button {
  background-color: #ff7b00;
  color: #ffffff;
  font-size: 14px;
  height: 37px;
}

.cancel-button {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  font-size: 14px;
  height: 37px;
}

.back-button {
  padding: 8px 16px 8px 16px;
  background-color: #f5f5f5;
  color: #4b4b4b;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 15px;
  text-align: center;
}

.icon {
  margin-left: 8px;
  cursor: pointer;
}

.cipa-icon {
  margin-left: 50px;
}

.brigada-icon {
  margin-left: 5px;
}

.txt-secondary {
  font-size: 14px;
}

.loader {
  border: 2px solid #f3f3f3;
  border-radius: 100%;
  border-top: 2px solid #e87530;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 0.8s linear infinite; /* Safari */
  animation: spin 0.8s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
