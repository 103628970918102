<template>
  <router-link :to="link" class="link">
    <div class="user card" v-bind:class="[isSelected ? 'selected-card' : '']">
      <div class="d-flex align-items-center">
        <Avatar
          :username="formattedNickname(user.nickname)"
          :src="photoUrl"
          :size="40"
          background-color="#E87305"
          color="#fff"
        ></Avatar>
        <div class="ps-3">
          <div class="txt-primary fw-bold">{{ titleCase(user.nickname) }}</div>
          <div class="txt-secondary">{{ user.email }}</div>
          <div class="txt-secondary">
            {{
              user.galera &&
              user.galera.communication &&
              user.galera.communication.cellphone
            }}
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import Avatar from "vue-avatar";
import { formattedNickname, titleCase } from "../../helpers/name";

export default {
  components: {
    Avatar,
  },
  props: { user: { type: Object } },
  computed: {
    link() {
      return `/users/${this.user && this.user.login}`;
    },
    isSelected() {
      return (
        this.$route &&
        this.$route.params &&
        this.user &&
        this.$route.params.login == this.user.login
      );
    },
    photoUrl() {
      return this.user.login
        ? `${this.$store.state.baseUrl}/photo/${this.user.login}`
        : "";
    },
  },
  methods: {
    formattedNickname(nickname) {
      return formattedNickname(nickname);
    },
    titleCase(nickname) {
      return titleCase(nickname);
    },
  },
};
</script>
<style scoped lang="scss">
.user.card {
  border-top: 1px solid #f5f5f5;
  cursor: pointer;
  height: 62px;
  width: 100%;
  color: #808080;
  display: flex;
  align-items: center;
}

.txt-primary {
  font-size: 12px;
  color: #000000;
}

.link {
  text-decoration: none;
}

.selected-card {
  background-color: #fafafa;
}

.txt-secondary {
  font-size: 11px;
}
</style>
