<template>
  <div :class="isMobile ? 'mobile-layout' : 'desktop-layout'">
    <el-collapse v-if="!isLoading" accordion @change="resetNewSkill">
      <el-collapse-item v-for="tag in tags" :title="tag.title" :key="tag.name">
        <div v-if="tag.subtitle" class="skill-info">
          <div>{{ tag.subtitle }}</div>
        </div>

        <div v-if="isEditable && hasAdditionalSkills(tag.name)" class="addSkill">
          <el-autocomplete
            v-model="newSkillName"
            :fetch-suggestions="querySearch"
            clearable
            class="skill-search"
            :tag="tag.name"
            placeholder="Competências adicionais"
            @select="handleSelect"
          >
            <template #default="{ item }">
              <div>{{ item.name }}</div>
            </template>
          </el-autocomplete>

          <td v-if="newSkill.type === 'rating'">
            <el-radio-group
              v-model="newSkill.response"
              class="skill-level-selection"
            >
              <el-radio :label="1" size="small">1</el-radio>
              <el-radio :label="2" size="small">2</el-radio>
              <el-radio :label="3" size="small">3</el-radio>
              <el-radio :label="4" size="small">4</el-radio>
              <el-radio :label="5" size="small">5</el-radio>
            </el-radio-group>
          </td>

          <el-button
            link
            type="primary"
            size="small"
            @click="addSkill(newSkill)"
          >
            Adicionar
          </el-button>
        </div>

        <table>
          <tr v-for="userSkill in getTagSkills(tag.name)"
              :key="userSkill.name"
          >
            <td v-if="userSkill.type === 'rating'">
              {{ userSkill.name }}
            </td>
            <td class="user-skill" v-if="userSkill.type === 'rating'">
              <el-radio-group
                v-model="userSkill.response"
                :disabled="!isEditable"
                @change="updateUserSkills(userSkill)"
                class="skill-level-selection"
              >
                <el-radio :label="1" size="small">1</el-radio>
                <el-radio :label="2" size="small">2</el-radio>
                <el-radio :label="3" size="small">3</el-radio>
                <el-radio :label="4" size="small">4</el-radio>
                <el-radio :label="5" size="small">5</el-radio>
              </el-radio-group>
            </td>

            <td v-if="userSkill.type === 'check'">
              <el-checkbox
                v-model="userSkill.response"
                :label="userSkill.name"
                size="large"
                :disabled="!isEditable"
                @change="updateUserSkills(userSkill)"
                class="skill-level-selection"
              />
            </td>

            <td v-if="isEditable && !isMandatory(userSkill)">
              <el-button
                link
                type="primary"
                size="small"
                :disabled="isMandatory(userSkill)"
                @click="deleteSkill(userSkill)"
              >
                Apagar
              </el-button>
            </td>
          </tr>
        </table>
      </el-collapse-item>
    </el-collapse>
    <div
      v-else-if="isLoading"
      class="col d-flex align-items-center flex-column mt-5"
    >
      <div class="loader mt-5" />
      <div class="txt-galera">
        Carregando informações...
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    formUser: Object,
    isEditable: Boolean,
    isMobile: Boolean,
  },
  data() {
    return {
      tags: [],
      skills: [],
      newSkill: {},
      newSkillName: '',
      isLoading: false,
    }
  },
  async mounted() {
    this.isLoading = true;
    const data = await this.getSkills();
    this.tags = data.tags;
    this.skills = this.loadResponses(data.skills);
    this.isLoading = false;

    const radioGroupList = document.getElementsByClassName('el-radio__input is-disabled');
    Array.from(radioGroupList).forEach(function (radioGroup) {
      radioGroup.classList.remove("is-disabled");
    })
  },
  async updated() {
    const collapsableItem = document.getElementsByClassName('el-collapse-item__header ');
    Array.from(collapsableItem).forEach(function (radioGroup) {
      radioGroup.style.lineHeight = '14px';
    })
  },
  methods: {
    addSkill(skill) {
      if ((skill['type'] === 'rating') && !skill['response']) {
        this.$toast.open({
          message: `Selecione um valor para essa competência`,
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-left",
        });
      } else if (skill?.name) {
        if (!this.formUser.galera.skills) {
          this.formUser.galera.skills = [];
        }

        if (skill['type'] === 'check') {
          skill['response'] = true;
        }

        this.updateSkills(skill);
        this.updateUserSkills(skill);
        this.newSkill = {};
        this.newSkillName = '';
      } else {
        this.$toast.open({
          message: "Selecione uma competência!",
          type: "error",
          duration: 10000,
          dismissible: true,
          position: "bottom-left",
        });
      }
    },
    deleteSkill(skill) {
      skill['response'] = undefined;
      const index = this.formUser.galera.skills.findIndex(userSkill => userSkill.name === skill.name);
      this.formUser.galera.skills.splice(index, 1);
      this.$forceUpdate();
    },
    updateSkills(skill) {
      const userSkill = this.skills?.find(userSkill => userSkill.name === skill.name);
      if (!userSkill) {
        this.skills.push({name: skill.name, response: skill.response});
      } else {
        userSkill['response'] = skill.response;
      }
    },
    updateUserSkills(skill) {
      const userSkill = this.formUser?.galera?.skills?.find(userSkill => userSkill.name === skill.name);
      if (!userSkill) {
        this.formUser.galera.skills.push({name: skill.name, response: skill.response});
      } else {
        userSkill['response'] = skill.response;
      }
      this.$forceUpdate();
    },
    isMandatory(skill) {
      if (!skill.mandatory) return false;

      return this.formUser.qulture?.teams?.find(team => skill.mandatory.indexOf(team.toLowerCase()) >= 0);
    },
    getTagSkills(tagName) {
      return this.skills.filter(skill => skill.tags.indexOf(tagName) >= 0 && (this.isMandatory(skill) || skill.response));
    },
    loadResponses(skills) {
      if (!this.formUser.galera.skills) {
        this.formUser.galera.skills = [];
      }

      this.formUser.galera.skills.forEach(userSkill => {
        const skill = skills?.find(skill => userSkill.name === skill.name);
        skill['response'] = userSkill.response;
      });
      return skills;
    },
    async getSkills(queryString = '') {
      const results = await this.$axios.post("/skills/search",
        { query: queryString },
        { withCredentials: true }
      );
      return results.data;
    },
    hasAdditionalSkills(tagName) {
      return this.additionalSkills(tagName)?.length > 0;
    },
    additionalSkills(tagName) {
      return this.skills.filter(skill => !this.isMandatory(skill) && !skill.response && (skill.tags.indexOf(tagName) >= 0));
    },
    querySearch(queryString, cb) {
      const tagName = document.activeElement.getAttribute('tag');
      let skills = this.additionalSkills(tagName);
      skills = _.sortBy(skills, 'name')

      const results = queryString
        ? skills.filter(skill => skill.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
        : skills
      cb(results)
    },
    handleSelect(item) {
      this.newSkillName = item.name;
      this.newSkill['name'] = item.name;
      this.newSkill['tags'] = item.tags;
      this.newSkill['mandatory'] = item.mandatory;
      this.newSkill['type'] = item.type;
    },
    resetNewSkill() {
      this.newSkill = {};
      this.newSkillName = '';
    }
  },
};
</script>

<style scoped lang="scss">
.desktop-layout {
  padding: 8px 0 8px 0;
  border-collapse: separate;
  border-spacing: 0 15px;
  width: 70vw;

  .skill-level-selection {
    margin: 10px;
    width: 300px;
  }
}

.mobile-layout {
  width: 100vw;

  .addSkill {
    flex-direction: column;
  }

  .skill-level-selection {
    margin: 10px;
  }
}

.skill-info {
  font-style: italic;
  margin-bottom: 20px;
}

.addSkill {
  display: flex;
  margin-bottom: 20px;

  .skill-search {
    width: 85%;
    padding-right: 10px;
  }
}

table {
  width: 90%;

  .user-skill-header {
    padding-right: 150px;
    float: right;
  }

  .required-span {
    color: red;
  }

  .user-skill {
    float: right;
  }

  tr:not(:last-child) {
    border-bottom: solid 1px #80808075;
  }
}

.txt-galera {
  color: #e87530;
  text-align: center;
}

.loader {
  border: 2px solid #f3f3f3;
  border-radius: 100%;
  border-top: 2px solid #e87530;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 0.8s linear infinite; /* Safari */
  animation: spin 0.8s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
