<template>
  <div>
    <div class="professional-title">Dados Profissionais</div>

    <div class="d-flex label">Matrícula:</div>

    <div
      v-if="user.latest && user.latest.relationship && user.latest.relationship.badge"
      class="d-flex information-text fw-bold"
    >
      {{ user.latest && user.latest.relationship && user.latest.relationship.badge }}
    </div>
    <div v-else class="d-flex information-text unknown">Não informada</div>
    <div class="d-flex label">Conhecido Por:</div>

    <div v-if="user.nickname" class="d-flex information-text fw-bold">
      {{ user.nickname }}
    </div>
    <div v-else class="d-flex information-text unknown">Não informado</div>

    <div class="d-flex label">E-mail:</div>

    <div v-if="user.email" class="d-flex information-text fw-bold">
      {{ user.email }}
    </div>
    <div v-else class="d-flex information-text unknown">Não informado</div>

    <div class="d-flex label">Times:</div>

    <div v-if="user.qulture && user.qulture.teams" class="d-flex information-text fw-bold">
      {{ user.qulture.teams.toString().replace(",", ", ") }}
    </div>
    <div v-else class="d-flex information-text unknown">Não informada</div>

    <div class="d-flex label">CC de Origem:</div>

    <div v-if="ccOrigem" class="d-flex information-text fw-bold">{{ ccOrigem }}</div>
    <div v-else class="d-flex information-text unknown">Não informado</div>

    <div class="d-flex label">Slack ID:</div>

    <div class="d-flex">
      <div v-if="user.slack" class="d-flex link information-text blue fw-bold">
        {{ user.slack }}
      </div>
      <div v-else class="d-flex information-text unknown">Não informado</div>
      <img
        v-if="user.slack"
        @click="slackRedirect"
        class="icon"
        src="../../assets/icons/slack_mark.svg"
        alt="SLACK"
        title="Conversar pelo slack"
      />
    </div>
    <div class="d-flex label">Currículo Lattes:</div>

    <a
      v-if="user.galera && user.galera.lattes"
      @click="lattesRedirect"
      class="d-flex url-linked link information-text blue fw-bold"
    >
      {{ user.galera && user.galera.lattes }}
    </a>
    <div v-else class="d-flex information-text unknown">Não informado</div>

    <div class="d-flex label">LinkedIn:</div>

    <a
      v-if="user.galera && user.galera.linkedin"
      @click="linkedinRedirect"
      class="d-flex url-linked link information-text blue fw-bold"
    >
      {{ user.galera && user.galera.linkedin }}
    </a>
    <div v-else class="d-flex information-text unknown">Não informado</div>

    <div class="personal-title">Dados Pessoais</div>

    <div class="d-flex label">Celular:</div>

    <div class="d-flex">
      <div
        v-if="
          user.galera && user.galera.communication && user.galera.communication.cellphone
        "
        class="d-flex information-text blue fw-bold"
      >
        {{
          user.galera &&
          user.galera.communication &&
          user.galera.communication.cellphone
        }}
      </div>
      <div v-else class="d-flex information-text unknown">Não informado</div>
      <img
        v-if="
          user.galera && user.galera.communication && user.galera.communication.cellphone
        "
        @click="whatsappRedirect"
        class="icon"
        src="../../assets/icons/whats_app.svg"
        alt="WHATSAPP"
        title="Conversar pelo WhatsApp"
      />
    </div>

    <div class="d-flex label">Contato Familiar:</div>

    <div
      v-if="
        user.galera &&
        user.galera.communication &&
        user.galera.communication.family_contact &&
        user.galera.communication.family_contact.name
      "
      class="d-flex information-text fw-bold"
    >
      {{
        user.galera &&
        user.galera.communication &&
        user.galera.communication.family_contact &&
        user.galera.communication.family_contact.name
      }}
    </div>
    <div v-else class="d-flex information-text unknown">Não informado</div>

    <div class="d-flex label">Telefone do Familiar:</div>

    <div
      v-if="
        user.galera &&
        user.galera.communication &&
        user.galera.communication.family_contact &&
        user.galera.communication.family_contact.cellphone
      "
      class="d-flex information-text fw-bold"
    >
      {{
        user.galera &&
        user.galera.communication &&
        user.galera.communication.family_contact &&
        user.galera.communication.family_contact.cellphone
      }}
    </div>
    <div v-else class="d-flex information-text unknown">Não informado</div>

    <div v-if="own && !isPrestadorOuOutros">
      <div class="personal-title">Dados Pessoais Sensíveis</div>
    </div>
    <div v-if="own && !isPrestadorOuOutros">
      <div class="d-flex label">Tamanho da Camisa:</div>

      <div
        v-if="
          user.galera &&
          user.galera.sensitive &&
          user.galera.sensitive.sizes &&
          user.galera.sensitive.sizes.tshirt
        "
        class="d-flex information-text fw-bold"
      >
        {{
          user.galera &&
          user.galera.sensitive &&
          user.galera.sensitive.sizes &&
          user.galera.sensitive.sizes.tshirt
        }}
      </div>
      <div v-else class="d-flex information-text unknown">Não informado</div>
    </div>
    <div v-if="own && !isPrestadorOuOutros">
      <div class="d-flex label">Nº do sapato:</div>

      <div
        v-if="
          user.galera &&
          user.galera.sensitive &&
          user.galera.sensitive.sizes &&
          user.galera.sensitive.sizes.shoes
        "
        class="d-flex information-text fw-bold"
      >
        {{
          user.galera &&
          user.galera.sensitive &&
          user.galera.sensitive.sizes &&
          user.galera.sensitive.sizes.shoes
        }}
      </div>
      <div v-else class="d-flex information-text unknown">Não informado</div>
    </div>
    <div v-if="own && !isPrestadorOuOutros">
      <div class="d-flex label">Possui filho(s)?:</div>

      <div
        v-if="
          user.galera &&
          user.galera.sensitive &&
          user.galera.sensitive.special &&
          user.galera.sensitive.special.has_children
        "
        class="d-flex information-text fw-bold"
      >
        {{
          user.galera &&
          user.galera.sensitive &&
          user.galera.sensitive.special &&
          user.galera.sensitive.special.has_children
        }}
      </div>
      <div v-else class="d-flex information-text unknown">Não informado</div>
    </div>
    <div v-if="own && !isPrestadorOuOutros">
      <div class="d-flex label">Cor/Raça:</div>

      <div
        v-if="
          user.galera &&
          user.galera.sensitive &&
          user.galera.sensitive.special &&
          user.galera.sensitive.special.race
        "
        class="d-flex information-text fw-bold"
      >
        {{
          user.galera &&
          user.galera.sensitive &&
          user.galera.sensitive.special &&
          user.galera.sensitive.special.race
        }}
      </div>
      <div v-else class="d-flex information-text unknown">Não informada</div>
    </div>
    <div v-if="own && !isPrestadorOuOutros">
      <div class="d-flex label">Sexo/Gênero:</div>

      <div
        v-if="
          user.galera &&
          user.galera.sensitive &&
          user.galera.sensitive.special &&
          user.galera.sensitive.special.gender
        "
        class="d-flex information-text fw-bold"
      >
        {{
          user.galera &&
          user.galera.sensitive &&
          user.galera.sensitive.special &&
          user.galera.sensitive.special.gender
        }}
      </div>
      <div v-else class="d-flex information-text unknown">Não informado</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: Object,
    ccOrigem: String,
    slackRedirect: Function,
    whatsappRedirect: Function,
    linkedinRedirect: Function,
    lattesRedirect: Function,
    isPrestadorOuOutros: Boolean,
  },
  computed: {
    own() {
      return (
        this.user &&
        this.$store.state &&
        this.$store.state.user &&
        this.user.login == this.$store.state.user.login
      );
    },
  },
};
</script>

<style scoped lang="scss">
.internal-box {
}

.icon {
  margin-left: 8px;
  cursor: pointer;
  margin-bottom: 4px;
}

.label {
  margin-right: 20px;
  color: #4b4b4b;
  font-size: 11px;
  margin-top: 7px;
  margin-bottom: 5px;
  text-align: right;
  white-space: nowrap;
  justify-content: flex-start;
}

.professional-title {
  font-size: 16px;
  color: #a8a8a8;
  margin-top: 26px;
  margin-bottom: 20px;
}

.information-text {
  color: #4b4b4b;
  font-size: 14px;
  margin-bottom: 4px;
  display: table-cell;
}

.information-text.blue {
  color: #0493c7;
}

.link {
  text-decoration: none;
  color: #0493c7;
}

.url-linked {
  cursor: pointer;
}

.personal-title {
  font-size: 16px;
  color: #a8a8a8;
  margin-top: 26px;
  margin-bottom: 20px;
}

.unknown {
  color: #a8a8a8;
}
</style>
