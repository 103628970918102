export default class ApiMiddleware {
  setVm(vm) {
    this.vm = vm;
  }

  onResponseError(error = {}) {
    if (this.vm) {
      if (error.response && error.response.status === 401) {
        if (this.vm.$router.history.current.path != '/login') {
          this.vm.$router.push({ path: '/login' });
          if (error.response.data == "Session expired") {
            this.vm.$toast.open({
              message: `Não autenticado, efetue o login!`,
              type: "warning",
              duration: 5000,
              dismissible: true,
              position: "bottom-left",
            });
          }
        }
      } else if (error.response && error.response.status === 500) {
        // eslint-disable-next-line 
        let errorMessage = 'Desculpe, estamos com problemas no servidor. Por favor, tente novamente em alguns minutos';
        // TODO Open alert
        //createToast(errorMessage);
        this.vm.$toast.open({
          message: errorMessage,
          type: "error",
          duration: 5000,
          dismissible: true,
          position: 'bottom-left'
        })
      } else if (error.response && error.response.status === 400) {
        this.vm.$toast.open({
          message: `Erro: ${error.response.data}`,
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-left",
        });
      } else if (error.response && error.response.status === 404) {
        let errorMessage = 'Lamentamos, mas a página que você procura não foi encontrada. Se o problema persistir, entre em contato com a equipe';
        this.vm.$toast.open({
          message: errorMessage,
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-left",
        });
      } else {
        // eslint-disable-next-line 
        let errorMessage = 'Desculpe, ocorreu uma falha no Galera. Favor entrar em contato com a equipe. Erro: ';
        errorMessage += JSON.stringify(error);
        // TODO Open alert
        this.vm.$toast.open({
          message: errorMessage,
          type: "error",
          duration: 5000,
          dismissible: true,
          position: 'bottom-left'
        })
      }
    }
    throw error.response;
  }
}