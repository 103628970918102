var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row navbar mb-3"},[_c('div',{staticClass:"col"},[_c('nav',{staticClass:"d-flex flex-row galera-nav-bar"},[_c('div',[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"logoheader",attrs:{"alt":"Logo do Galera","src":require("../../assets/img/Galera_Header.svg")}})])],1),_c('div',{staticClass:"d-flex flex-row links-sections"},[(_vm.hasAuthorization && !_vm.isMobile)?_c('div',{staticClass:"d-flex links-subsection"},[_c('div',{staticClass:"d-flex",class:_vm.routeName == 'Home' || _vm.routeName == 'User'
                ? 'link-bearer-selected'
                : 'link-bearer'},[_c('router-link',{class:_vm.routeName == 'Home' || _vm.routeName == 'User' ? 'link-selected' : 'link',attrs:{"to":"/"}},[_vm._v(" Colaboradores ")])],1),(_vm.isAuthorized([_vm.Role.Reports]))?_c('div',{staticClass:"d-flex link-bearer",class:_vm.routeName == 'Reports' ? 'link-bearer-selected' : 'link-bearer'},[_c('a',{staticClass:"link",attrs:{"href":"https://datastudio.google.com/","target":"_blank"}},[_vm._v(" Relatórios ")])]):_vm._e(),(_vm.isAuthorized([_vm.Role.Relationship]))?_c('div',{staticClass:"d-flex link-bearer",class:_vm.routeName == 'Relationships' ||
              _vm.routeName == 'Employee' ||
              _vm.routeName == 'Costcenter'
                ? 'link-bearer-selected'
                : 'link-bearer'},[_c('router-link',{class:_vm.routeName == 'Relationships' ||
                _vm.routeName == 'Employee' ||
                _vm.routeName == 'Costcenter'
                  ? 'link-selected'
                  : 'link',attrs:{"to":"/relationships"}},[_vm._v(" Vínculos ")])],1):_vm._e(),(_vm.isAuthorized([_vm.Role.Outsourced]))?_c('div',{staticClass:"d-flex link-bearer",class:_vm.routeName == 'Outsourced' ? 'link-bearer-selected' : 'link-bearer'},[_c('router-link',{class:_vm.routeName == 'Outsourced' ? 'link-selected' : 'link',attrs:{"to":"/outsourced"}},[_vm._v(" Terceiros ")])],1):_vm._e(),(_vm.isAuthorized([_vm.Role.Admin]))?_c('div',{staticClass:"d-flex link-bearer",class:_vm.routeName == 'Admin' ? 'link-bearer-selected' : 'link-bearer'},[_c('router-link',{class:_vm.routeName == 'Admin' ? 'link-selected' : 'link',attrs:{"to":"/admin"}},[_vm._v(" Admin ")])],1):_vm._e(),(_vm.isAuthorized([_vm.Role.Email]))?_c('div',{staticClass:"d-flex link-bearer",class:_vm.routeName == 'EmailDefinition' ? 'link-bearer-selected' : 'link-bearer'},[_c('router-link',{class:_vm.routeName == 'EmailDefinition' ? 'link-selected' : 'link',attrs:{"to":"/email_definition"}},[_vm._v(" Definir E-mail ")])],1):_vm._e()]):_vm._e()]),_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('AppsMenu'),_c('ProfileMenu',{attrs:{"user":_vm.user}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }