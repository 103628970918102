import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import Home from '../views/Home.vue';
import User from '../views/User.vue';
import Skills from '../views/Skills.vue';
import NotFound from '../views/NotFound.vue';
import Role from '../helpers/role'
import _  from 'lodash'

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: 'email_definition',
        name: 'EmailDefinition',
        component: () => import(/* webpackChunkName: "email-def" */'../views/EmailDefinition.vue'),
        meta: { authorize: [Role.Email] } ,
      },
      {
        path: 'users/:login',
        name: 'User',
        component: User,
        meta: { authorize: [] } ,
      },
      {
        path: 'skills/:login',
        name: 'Skills',
        component: Skills,
        meta: { authorize: [] } ,
      },
      {
        path: 'user/edit',
        name: 'Edit',
        component: () => import(/* webpackChunkName: "edit" */'../views/Edit.vue'),
        meta: { authorize: [] } ,
      },
      {
        path: 'relationships',
        name: 'Relationships',
        component: () => import(/* webpackChunkName: "relationships" */'../views/Relationships.vue'),
        meta: { authorize: [Role.Relationship] } ,
        children: [
          {
            path: 'employee',
            name: 'Employee',
            component: () => import(/* webpackChunkName: "relationships-emp" */'../components/relationship/Employee.vue'),
            meta: { authorize: [Role.Relationship] } ,
          },
          {
            path: 'costcenter',
            name: 'Costcenter',
            component: () => import(/* webpackChunkName: "relationships-cc" */'../components/relationship/Costcenter.vue'),
            meta: { authorize: [Role.Relationship] } ,
          },
        ]
      },
      {
        path: 'admin',
        name: 'Admin',
        component: () => import(/* webpackChunkName: "admin" */'../views/Admin.vue'),
        meta: { authorize: [Role.Admin] } ,
      },
      {
        path: 'outsourced',
        name: 'Outsourced',
        component: () => import(/* webpackChunkName: "outsourced" */'../views/Outsourced.vue'),
        meta: { authorize: [Role.Outsourced] } ,
      },
    ]
  },
  {
    path: '/:pathMatch(.*)',
    name: 'NotFound',
    component: NotFound,
  },
];



const router = new VueRouter({
  routes,
});



export default router;
