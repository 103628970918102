<template>
  <div :class="isMobile ? 'user-mobile' : 'user'">
    <div v-if="!isLoading && user && !loadingUser">
      <div
        :class="
          'container-fluid d-flex justify-content-between ' +
            (isMobile ? 'header-mobile' : '')
        "
      >
        <div class="photo-section d-flex align-items-center">
          <Avatar
            :username="formattedNickname(user.nickname)"
            :size="100"
            :src="photoUrl"
            background-color="#e87305"
            color="#ffffff"
          />
          <div class="ps-3">
            <div class="txt-primary fw-bold">
              {{ user.nickname }}
            </div>
            <div class="txt-secondary">
              {{ user.email }}
            </div>
            <div class="d-flex">
              <div class="txt-secondary">
                {{
                  user.galera &&
                    user.galera.communication &&
                    user.galera.communication.cellphone
                    && formattedPhone(user.galera.communication.cellphone)
                }}
              </div>
              <img
                v-if="user.cipa"
                class="cipa-icon"
                src="../assets/icons/cipa.svg"
                alt="CIPA"
              >
              <img
                v-if="user.brigada"
                class="brigada-icon"
                src="../assets/icons/brigada.svg"
                alt="Brigada"
              >
            </div>
          </div>
        </div>
        <div :class="'d-flex align-items-start ' + (isMobile ? 'buttons-mobile' : '')">
          <router-link
            to="/"
            :class="'link ' + (isMobile ? 'button-mobile' : '')"
          >
            <div class="back-buttom fw-bold">
              Voltar
            </div>
          </router-link>
          <router-link
            v-if="own"
            :to="`/user/edit?${tabUrl}`"
            :class="'link ' + (isMobile ? 'button-mobile' : '')"
          >
            <div class="edit-buttom fw-bold">
              Editar
            </div>
          </router-link>
        </div>
      </div>
      <div class="horizontal-line" />
      <div class="information">
        <div v-if="showSkills">
          <el-tabs
            v-model="activeTab"
            class="demo-tabs"
            @tab-click="setActiveTab"
          >
            <el-tab-pane
              label="Geral"
              name="default"
            >
              <div v-if="isMobile">
                <UserInfoMobile
                  :user="user"
                  :cc-origem="ccOrigem"
                  :slack-redirect="slackRedirect"
                  :whatsapp-redirect="whatsappRedirect"
                  :linkedin-redirect="linkedinRedirect"
                  :lattes-redirect="lattesRedirect"
                  :is-prestador-ou-outros="isPrestadorOuOutros"
                />
              </div>
              <div v-else>
                <UserInfo
                  :user="user"
                  :cc-origem="ccOrigem"
                  :slack-redirect="slackRedirect"
                  :whatsapp-redirect="whatsappRedirect"
                  :linkedin-redirect="linkedinRedirect"
                  :lattes-redirect="lattesRedirect"
                  :is-prestador-ou-outros="isPrestadorOuOutros"
                  :is-c-l-t-or-estagiario="isCLTOrEstagiario"
                />
              </div>
            </el-tab-pane>
            <el-tab-pane
              label="Competências"
              name="skills"
            >
              <EditUserSkills
                :form-user="user"
                :is-editable="false"
                :is-mobile="isMobile"
              />
            </el-tab-pane>
          </el-tabs>
        </div>
        <div v-else>
          <div v-if="isMobile">
            <UserInfoMobile
              :user="user"
              :cc-origem="ccOrigem"
              :slack-redirect="slackRedirect"
              :whatsapp-redirect="whatsappRedirect"
              :linkedin-redirect="linkedinRedirect"
              :lattes-redirect="lattesRedirect"
              :is-prestador-ou-outros="isPrestadorOuOutros"
            />
          </div>
          <div v-else>
            <UserInfo
              :user="user"
              :cc-origem="ccOrigem"
              :slack-redirect="slackRedirect"
              :whatsapp-redirect="whatsappRedirect"
              :linkedin-redirect="linkedinRedirect"
              :lattes-redirect="lattesRedirect"
              :is-prestador-ou-outros="isPrestadorOuOutros"
              :is-c-l-t-or-estagiario="isCLTOrEstagiario"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-else-if="isLoading || loadingUser"
      class="col d-flex align-items-center flex-column mt-5"
    >
      <div class="loader mt-5" />
      <div class="txt-galera">
        Carregando informações...
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "vue-avatar";
import { formattedNickname } from "../helpers/name";
import UserInfo from "../components/user/UserInfo";
import UserInfoMobile from "../components/user/UserInfoMobile";
import EditUserSkills from "@/components/edit/EditUserSkills.vue";
import _ from 'lodash';
export default {
  components: {
    EditUserSkills,
    Avatar,
    UserInfo,
    UserInfoMobile,
  },
  data() {
    return {
      isLoading: false,
      auxUser: null,
      activeTab: 'default'
    };
  },
  computed: {
    ccOrigem() {
      return `${
        this.user.latest &&
        this.user.latest.costcenter &&
        this.user.latest.costcenter.code
      } - ${
        this.user.latest &&
        this.user.latest.costcenter &&
        this.user.latest.costcenter.name
      }`;
    },
    user() {
      return this.$route.params &&
        this.$route.params.login &&
        this.$store.state.user.login == this.$route.params.login
        ? this.$store.state.user
        : this.auxUser;
    },
    photoUrl() {
      return this.user.login
        ? `${this.$store.state.baseUrl}/photo/${this.user.login}`
        : "";
    },
    own() {
      return (
        this.user &&
        this.$store.state &&
        this.$store.state.user &&
        this.user.login == this.$store.state.user.login
      );
    },
    ownUser() {
      return this.$store.state.user;
    },
    isCLTOrEstagiario() {
      return !(
        this.user &&
        this.user.latest &&
        this.user.latest.relationship &&
        this.user.latest.relationship.type &&
        (this.user.latest.relationship.type != "CLT" &&
        this.user.latest.relationship.type != "Estagiário")
      );
    },
    isPrestadorOuOutros() {
      return (
        this.user &&
        this.user.latest &&
        this.user.latest.relationship &&
        this.user.latest.relationship.type &&
        (this.user.latest.relationship.type == "Prestador de Serviços" ||
          this.user.latest.relationship.type == "Outros")
      );
    },
    loadingUser() {
      return this.$store.state.loadingUser;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    showSkills() {
      return this.own && this.user?.galera?.skills?.length > 0;
    },
    tabUrl() {
      return 'activeTab=' + this.activeTab;
    },
  },
  watch: {
    async $route(to, from) {
      this.getUserData();
    },
    async ownUser(to, from) {
      this.getUserData();
    },
  },
  async created() {
    if (!this.$route.query.activeTab) {
      this.$router.push({path: this.$route.fullPath, query: {activeTab: this.activeTab} });
    } else {
      this.activeTab = this.$route.query.activeTab;
    }
    if (this.isMobile) {
      this.calculateHeight()
      window.addEventListener("resize", this.calculateHeight());
    }
    this.getUserData();
  },
  methods: {
    formattedPhone (phone) {
       if (phone.includes("_")) {
           let parts = phone.split("_");
           if(parts.length > 1) {
             return parts[1]
           }
           return parts[0]
       }
       return phone;
    },
    calculateHeight () {
      let vh = window.innerHeight - 138;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    formattedNickname(nickname) {
      return formattedNickname(nickname);
    },
    whatsappRedirect() {
      const phone = this.user.galera.communication.cellphone.replace(/[{()+_A-Z\s\-}]/g, "");
      const win = window.open(`https://wa.me/${
        this.user.galera.communication.cellphone.includes("+") ? '' : '55'}${phone}`, "_blank");
      win.focus();
    },
    slackRedirect() {
      const win = window.open(
        `https://cesar.slack.com/app_redirect?channel=${this.user.slack}`,
        "_blank"
      );
      win.focus();
    },
    lattesRedirect() {
      let lattes = this.user.galera && this.user.galera.lattes;
      if (lattes) {
        let win = window.open(lattes, "_blank");
        win.focus();
      }
    },
    linkedinRedirect() {
      let linkedin = this.user.galera && this.user.galera.linkedin;
      if (linkedin) {
        let win = window.open(linkedin, "_blank");
        win.focus();
      }
    },
    isAbleToGetUserData() {
      return (
        this.$route.params &&
        this.$route.params.login &&
        this.$store.state.user &&
        this.$store.state.user.login &&
        (!this.auxUser || this.auxUser.login != this.$route.params.login) &&
        this.$route.params.login != this.$store.state.user.login
      );
    },
    async getUserData() {
      if (this.isAbleToGetUserData()) {
        this.isLoading = true;
        try {
          const resp = await this.$axios.get("people/user/" + this.$route.params.login, {
            withCredentials: true,
          });
          this.auxUser = resp.data;
        } catch (error) {
          this.$router.push({ path: "/" });
        } finally {
          this.isLoading = false;
        }
      }
    },
    setActiveTab() {
      this.$router.push({path: this.$route.fullPath, query: {activeTab: this.activeTab} });
    },
  },
};
</script>

<style scoped lang="scss">
.buttons-mobile {
  margin-top: 27px;
}

.button-mobile {
  flex: 1;
}

.header-mobile {
  flex-direction: column;
}

.txt-primary {
  font-size: 18px;
  color: #000000;
}

.user {
  max-height: calc(100vh - 138px);
}

.user-mobile {
  max-height: var(--vh, 100vh);
}

.information {
  margin-left: 15px;
  display: flex;
}

.horizontal-line {
  height: 1px;
  margin-top: 30px;
}

.professional-section {
  flex-direction: row;
}

.personal-section {
  flex-direction: row;
}

.internal-box {
}

.label-cell {
  display: table-cell;
}

.link {
  text-decoration: none;
}

.edit-buttom {
  padding: 8px 16px 8px 16px;
  background-color: #ff7b00;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}

.back-buttom {
  padding: 8px 16px 8px 16px;
  background-color: #f5f5f5;
  color: #4b4b4b;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 15px;
  text-align: center;
}

.link {
  text-decoration: none;
}

.icon {
  margin-left: 8px;
  cursor: pointer;
}

.cipa-icon {
  margin-left: 50px;
}

.brigada-icon {
  margin-left: 5px;
}

.label {
  margin-right: 20px;
  color: #4b4b4b;
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 4px;
  text-align: right;
  white-space: nowrap;
  margin-left: 12px;
  justify-content: flex-end;
}

.information-text {
  color: #4b4b4b;
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 4px;
  display: table-cell;
}

.information-text.blue {
  color: #0493c7;
}

.txt-secondary {
  font-size: 14px;
}
.professional-title {
  font-size: 16px;
  color: #a8a8a8;
  margin-top: 26px;
  margin-bottom: 20px;
}
.personal-title {
  font-size: 16px;
  color: #a8a8a8;
  margin-top: 26px;
  margin-bottom: 20px;
}

.loader {
  border: 2px solid #f3f3f3;
  border-radius: 100%;
  border-top: 2px solid #e87530;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 0.8s linear infinite; /* Safari */
  animation: spin 0.8s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
